import { inject, untracked } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, UrlTree } from '@angular/router';
import { IdpService } from '@icp/angular/idp';
import { dispatchWhenNotLoaded, selectStateDone } from '@icp/angular/ngrx';
import { AppSessionDto, AppSessionsService, SessionPlatform } from '@icp/interfaces';
import { Store } from '@ngrx/store';
import { filter, first, map, Observable, of, switchMap, tap, throwError, timer } from 'rxjs';

import { selectAppSessionId, selectUserSessionState, selectUserStoresState } from '../store';
import { getAppSession, getAppSessionSuccess, listUserStores, updateAppSession } from '../store/auth.actions';

function checkSessionStoreId(store: Store, route: ActivatedRouteSnapshot, router: Router, session: AppSessionDto) {
    dispatchWhenNotLoaded(store, selectUserStoresState, listUserStores());
    return store.select(selectUserStoresState).pipe(
        filter(selectStateDone),
        map((state) => state.result),
        first(),
        switchMap((stores): Observable<UrlTree | boolean> => {
            if (session.store) {
                // store already set created. All good.
                return of(true);
            }
            if (stores.length === 0) {
                // prevent infinite loop when this guard is used on the create-business page itself
                if (route.url[0].path === 'create-business') {
                    return of(true);
                }
                return of(router.createUrlTree(['/create-business']));
            } else if (stores.length === 1) {
                store.dispatch(updateAppSession({ payload: { storeId: stores[0].id } }));
                // Need to defer as the state is not immediately updated by above statement.
                return timer(1).pipe(
                    switchMap(() =>
                        store.select(selectUserSessionState).pipe(
                            filter(selectStateDone),
                            first(),
                            map(() => true),
                        ),
                    ),
                );
            } else {
                return of(router.createUrlTree(['/store-selection']));
            }
        }),
    );
}

export const sessionOrSoftKeyGuard: CanActivateFn = (route) => {
    const idpService = inject(IdpService);
    const router = inject(Router);
    const store = inject(Store);
    const sessionsService = inject(AppSessionsService);
    return idpService.getCurrentSession().pipe(
        switchMap((session): Observable<UrlTree | boolean> => {
            if (!session) {
                return of(router.createUrlTree(['/auth/login']));
            }
            const decodedToken = idpService.decodeAccessTokenJwt(session.accessToken);
            if (decodedToken.token_kind === 'user') {
                const sessionId = untracked(() => store.selectSignal(selectAppSessionId)());
                if (sessionId) {
                    dispatchWhenNotLoaded(store, selectUserSessionState, getAppSession({ sessionId }));
                    return store.select(selectUserSessionState).pipe(
                        filter(selectStateDone),
                        switchMap((result) => checkSessionStoreId(store, route, router, result.result)),
                    );
                }
                return sessionsService.appCreateSession({ platform: SessionPlatform.WEB, pushToken: null }).pipe(
                    tap((session) => store.dispatch(getAppSessionSuccess({ session }))),
                    switchMap((session) => checkSessionStoreId(store, route, router, session)),
                );
            } else if (decodedToken.token_kind !== 'soft-key') {
                return throwError(() => new Error(`Invalid token_kind ${decodedToken.token_kind}!`));
            }
            return of(true);
        }),
    );
};
