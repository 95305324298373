import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { dispatchWhenNotLoaded } from '@icp/angular/ngrx';
import { Store } from '@ngrx/store';

import { getCirkloStoreInfo, getCirkloUserInfo } from '../store/auth.actions';
import { authFeature } from '../store/auth.feature';

export const ensureCirkloStoreInfoLoadedGuard: CanActivateFn = () => {
    dispatchWhenNotLoaded(inject(Store), authFeature.selectStoreInfo, getCirkloStoreInfo());
    return true;
};

export const ensureCirkloUserInfoLoadedGuard: CanActivateFn = () => {
    dispatchWhenNotLoaded(inject(Store), authFeature.selectUserInfo, getCirkloUserInfo());
    return true;
};
