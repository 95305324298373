import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { IdpService } from '@icp/angular/idp';

export const IsAuthenticatedGuard: CanActivateFn = async () => {
    const idpService = inject(IdpService);
    const router = inject(Router);
    const isSignedIn = await idpService.isSignedIn();
    return isSignedIn ? true : router.createUrlTree(['/welcome']);
};
